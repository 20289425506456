var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ErrorLogSettingsStyled', [_c('ErrorTypeFilterMolecule', {
    attrs: {
      "widgetSettings": _vm.widgetSettings
    },
    on: {
      "changeWidgetSettings": function changeWidgetSettings($event) {
        return _vm.$emit('changeWidgetSettings', $event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }